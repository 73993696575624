import { useTranslation } from 'react-i18next';
import { RESERVED_AREA_URL } from '../utils/consts';
import { ORDER_SHIPPING_TYPES, ORDER_STATUS } from '../utils/orderStatus';
import { Typography } from '@photosi/web-design-system';
import { Alert } from './Alert';
import { TrackingLink } from './TrackingLink';
import { OrderItemTextsProps } from '../features/components/OrderItemTexts';
import { Icon } from '@photosi/web-design-system';

const SecondParagraph: React.FC<Partial<OrderItemTextsProps>> = ({
  currentAppOrderStatus,
  externalTrackingUrl,
  orderStatus,
  shippingType,
  userAddress,
  isShippingAddressEditable,
  orderId,
  isShippingDelayed,
  deliveryDate,
  deliverySign,
}: any) => {
  const { t } = useTranslation('tracking');
  const isMailOrderOrExpress =
    shippingType === ORDER_SHIPPING_TYPES.mailOrder || shippingType === ORDER_SHIPPING_TYPES.express;
  // isShippedStatus and isDeliveredStatus refer to tracking steps
  const isShippedStatus = currentAppOrderStatus === ORDER_STATUS.shipped;
  const isDeliveredStatus =
    currentAppOrderStatus === ORDER_STATUS.delivered || currentAppOrderStatus === ORDER_STATUS.readyToPickup;
  const isInProgress = orderStatus === ORDER_STATUS.created || orderStatus === ORDER_STATUS.inProgress;
  const isDelivered = orderStatus === ORDER_STATUS.delivered || orderStatus === ORDER_STATUS.readyToPickup;
  const isShipped = orderStatus === ORDER_STATUS.shipped;

  const hasTrackingUrl = externalTrackingUrl?.length > 0;
  const orderDeliveryAddress =
    shippingType === 'PickUpStore' ? `${userAddress.company}` : `${userAddress.firstName} ${userAddress.lastName}`;

  const date = new Date(deliveryDate);
  const deliveryTime = deliveryDate
    ? t('deliveryTime', {
        deliveryTime: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }),
      })
    : null;
  const recipientSignature = deliverySign ? t('recipientSignature', { signature: deliverySign }) : null;
  const deliveryAddressComponent = (
    <>
      {t('toBeShippedSub')} <Typography variant="headingS">{orderDeliveryAddress}</Typography> <br />
      {userAddress.street}, {userAddress.number} <br /> {userAddress.city} <br />
      {isDelivered && (
        <span className="pds-mt-2 pds-flex pds-flex-col">
          {recipientSignature}
          <br />
          {deliveryTime}
        </span>
      )}
    </>
  );
  let secondParagraph = t(`${currentAppOrderStatus}Sub`);

  if (isShippedStatus && isInProgress) {
    secondParagraph = t('toBeShippedSub');
  }
  if (isShippedStatus && isMailOrderOrExpress) secondParagraph = t('home');
  if (isShippedStatus && !isMailOrderOrExpress) secondParagraph = t('store');

  if (isDeliveredStatus) {
    secondParagraph = deliveryAddressComponent as unknown as string;
  }

  if (!isDelivered && isDeliveredStatus && isShippingDelayed) {
    return (
      <>
        <TrackingLink
          subtitle={t('shippingDelayed.subtitle')}
          tracking={t('tracking')}
          showLink={hasTrackingUrl}
          externalTrackingUrl={externalTrackingUrl}
        />
        <Alert title={t('orderAlreadyShipped')} description={t('canNotShippingAddress')} />
      </>
    );
  }

  if (isShippedStatus && isMailOrderOrExpress) {
    if (hasTrackingUrl) {
      return (
        <TrackingLink
          subtitle={t('home')}
          tracking={t('tracking')}
          showLink={!isShippingDelayed}
          externalTrackingUrl={externalTrackingUrl}
        />
      );
    }
    secondParagraph = (
      <Typography variant="bodyS">
        {t('home')} <br />
        <Typography variant="headingS">{t('missingTracking')}</Typography>
      </Typography>
    ) as unknown as string;
  }

  if (isShippingAddressEditable && isDeliveredStatus && isMailOrderOrExpress) {
    const editLink = `${RESERVED_AREA_URL}/orders/${orderId}/shipping-address`;
    return (
      <Typography variant="bodyS" data-testid="second-paragraph">
        {deliveryAddressComponent}
        <a
          href={editLink}
          data-external-tracking-link={externalTrackingUrl}
          target="_blank"
          rel="has-warning noopener noreferrer"
        >
          <Typography variant="bodyS" className="pds-flex pds-items-center">
            {t('changeShippingAddress')}
            <Icon name="arrowRight" />
          </Typography>
        </a>
      </Typography>
    );
  }
  if (!isShippingAddressEditable && !isDelivered && isDeliveredStatus && isMailOrderOrExpress && isShipped) {
    return (
      <>
        <Typography variant="bodyS">{deliveryAddressComponent}</Typography>
        <Alert title={t('orderAlreadyShipped')} description={t('canNotShippingAddress')} />
      </>
    );
  }

  return (
    <Typography variant="bodyS" data-testid="second-paragraph">
      {secondParagraph}
    </Typography>
  );
};

export default SecondParagraph;
